import { useMemo } from "react";

import { FetchResult, useMutation, useQuery } from "@apollo/client";
import { RetrieveIdVerification } from "./query/verification";
import {
	AdminSubmitLeadMutation,
	AdminSubmitLeadMutationVariables,
	GetLeadsByStageQuery,
	GetLeadsByStageQueryVariables,
	JourneyStage,
	Lead,
	LeadsOrderBy,
	RetrieveIdVerificationMutation,
	RetrieveIdVerificationMutationVariables,
} from "./graphql/types";
import { AdminSubmitLead, GetLeadsByStage } from "./query/leads";
import { ProductType } from "./products";

export enum Status {
	ALL = "ALL",
	PENDING = "PENDING",
	COMPLETED = "COMPLETED",
}

const getLeadJourney = (status: Status) => {
	switch (status) {
		case Status.COMPLETED:
			return {
				some: { stage: { in: [JourneyStage.IdComplete] } },
			};
		case Status.ALL:
		default:
			return {
				some: {
					stage: {
						in: [
							JourneyStage.IdPartial,
							JourneyStage.IdPartialAdmin,
							JourneyStage.IdInitiated,
							JourneyStage.IdSmsSent,
							JourneyStage.IdLinkOpened,
							JourneyStage.IdComplete,
						],
					},
				},
			};
	}
};

interface Filter {
	companyId: number;
	status: Status;
	limit?: number;
	offset?: number;
	search?: string;
}

const s = (text?: string) => ({
	includesInsensitive: text || "",
});

export const useIdLeads = ({
	companyId,
	status,
	limit,
	offset,
	search,
}: Filter) => {
	const variables: GetLeadsByStageQueryVariables = {
		condition: {
			companyId,
		},
		filter: {
			leadJourneys: getLeadJourney(status),
			leadJourneysExist: true,
			or: [
				{ firstName: s(search) },
				{ lastName: s(search) },
				{ phoneNumber: s(search) },
			],
		},
		first: limit,
		offset: offset,
		orderBy: [LeadsOrderBy.CreatedAtDesc],
	};

	const { data, loading, error } = useQuery<
		GetLeadsByStageQuery,
		GetLeadsByStageQueryVariables
	>(GetLeadsByStage, { variables });

	return {
		data: (data?.leads?.nodes || []) as Lead[],
		count: data?.leads?.totalCount,
		loading,
		error,
	};
};

export interface IRequestLeadActions {
	adminSubmitLead: (
		companyId: number,
		phoneNumber: string,
		productTypes: ProductType[],
		message: string,
		firstName: string,
		lastName: string,
		emailAddress: string,
		additionalInformation: string,
	) => Promise<FetchResult<AdminSubmitLeadMutation>>;
}

export const useRequestLead = () => {
	const [doAdminSubmitLead] = useMutation<
		AdminSubmitLeadMutation,
		AdminSubmitLeadMutationVariables
	>(AdminSubmitLead, {
		refetchQueries: [GetLeadsByStage],
		context: {
			headers: {
				"x-force-create-lead": "true",
			},
		},
	});

	const actions: IRequestLeadActions = useMemo(
		() => ({
			adminSubmitLead: (
				companyId: number,
				phoneNumber: string,
				productTypes: ProductType[],
				message: string,
				firstName: string,
				lastName: string,
				emailAddress: string,
				additionalInformation: string,
			) =>
				doAdminSubmitLead({
					variables: {
						companyId: companyId,
						messageOverride: message,
						input: {
							phoneNumber,
							firstName,
							lastName,
							emailAddress,
							additionalInformation: {
								feedbackIdUseCase: additionalInformation,
							},
						},
						productTypes: productTypes as any,
					},
				}),
		}),
		[doAdminSubmitLead],
	);

	return {
		actions,
	};
};

export interface IRetrieveIdActions {
	retrieveIdVerification: (
		leadId: number,
	) => Promise<FetchResult<RetrieveIdVerificationMutation>>;
}

export const useRetrieveIdVerification = () => {
	const [doRetrieveIdVerification, { data, loading }] = useMutation<
		RetrieveIdVerificationMutation,
		RetrieveIdVerificationMutationVariables
	>(RetrieveIdVerification);

	const actions: IRetrieveIdActions = useMemo(
		() => ({
			retrieveIdVerification: (leadId: number) =>
				doRetrieveIdVerification({
					variables: {
						leadId: leadId,
					},
				}),
		}),
		[doRetrieveIdVerification],
	);

	return {
		state: {
			loading,
			idVerified: data?.retrieveIdVerification?.data,
		},
		actions,
	};
};
